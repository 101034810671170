import { useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  ListGroupItem,
  ListGroup,
} from "reactstrap";

const BM_API = "https://bmapi-mock.vercel.app";

function App() {
  const [selectedHost] = useState(process.env.REACT_APP_SS_WIDGET_URL);
  const [userList, setUserList] = useState([]);
  const [loadingUserList, setLoadingUserList] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [username, setUsername] = useState("");
  const [signature, setSignature] = useState("");
  const url = `${selectedHost}?&username=${username}&authToken=${authToken}&signature=${signature}`;

  const handleUserSelection = (uId) => {
    if (!uId || !userList || userList.length === 0) return;
    console.log("handleUserSelection", uId);
    setSelectedUserId(uId);
    const details = userList.find(
      (u) => u.user_id.toString() === uId.toString()
    );
    if (!details) return;
    localStorage.setItem("selected_user_id", uId.toString());
    setUsername(details.username);
    setAuthToken(details.token);
  };

  const getBMUserList = async () => {
    const res = await fetch(`${BM_API}/api/users`);
    const data = await res.json();
    setUserList(data.users);
    const savedUserId = localStorage.getItem("selected_user_id");
    console.log({ savedUserId });
    if (savedUserId) {
      setSelectedUserId(savedUserId);
      const details = data.users.find(
        (u) => u.user_id.toString() === savedUserId.toString()
      );
      if (details) {
        setUsername(details.username);
        setAuthToken(details.token);
      }
    }
    setLoadingUserList(false);
  };

  const getEncryptionForMe = async () => {
    const res = await fetch(`${BM_API}/api/originenc`, {
      method: "post",
    });
    const data = await res.json();
    setSignature(encodeURIComponent(data.signature));
  };

  useEffect(() => {
    getBMUserList();
    getEncryptionForMe();
  }, []);

  return (
    <Container fluid style={{ height: "calc(100vh - 50px)" }}>
      <div
        className="text-center mb-1 fz-15"
        style={{
          fontSize: "0.75rem",
        }}
      >
        <h4 className="d-inline mx-2">Widget HOST</h4>
        <a href={`${BM_API}/users`} target="_blank" rel="noreferrer">
          Manage BM user list
        </a>{" "}
        <i class="bi bi-box-arrow-up-right"></i>
        <p className="text-center mb-1">{url}</p>
      </div>
      {/* <small className="text-center">{url}</small> */}
      <Row style={{ height: "100%" }}>
        <Col md="2" className="bg-light mb-2">
          <Form>
            <Row>
              <Col sm={12}>
                <FormGroup>
                  <Label for="exampleSelect">
                    Select user (api cached for 60sec)
                  </Label>
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    value={selectedUserId}
                    onChange={(e) => handleUserSelection(e.target.value)}
                    bsSize="sm"
                  >
                    <option value="">Not selected</option>
                    {userList.map((u) => (
                      <option key={u.user_id} value={u.user_id}>
                        {u.username}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col sm={12}>
                <FormGroup>
                  <Label for="authToken">Auth Token</Label>
                  <Input
                    id="authToken"
                    name="authToken"
                    type="text"
                    placeholder="Auth Token"
                    onChange={(e) => setAuthToken(e.target.value)}
                    value={authToken}
                    bsSize="sm"
                  />
                </FormGroup>
              </Col>
              <Col sm={12}>
                <FormGroup>
                  <Label for="username">Username</Label>
                  <Input
                    id="username"
                    name="username"
                    type="text"
                    placeholder="Username"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    bsSize="sm"
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <ListGroup>
            <ListGroupItem action tag="button">
              Racing
            </ListGroupItem>
            <ListGroupItem action tag="button">
              Sports
            </ListGroupItem>
            <ListGroupItem action active tag="button">
              Swopstakes
            </ListGroupItem>
          </ListGroup>
        </Col>
        {!loadingUserList && signature && (
          <Col className="">
            <iframe
              title="Swopstakes Widget"
              src={url}
              width="100%"
              height="100%"
            />
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default App;
